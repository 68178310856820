// You still need to register Vuetify itself
// src/plugins/vuetify.js

import Vue from "vue";
import Vuetify from "vuetify/lib";
import {
  VContainer,
  VRow,
  VCol,
  VApp,
  VProgressLinear,
  VProgressCircular,
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VApp,
    VRow,
    VCol,
    VContainer,
    VProgressLinear,
    VProgressCircular,
  },
});

const opts = {
  theme: {},
};

export default new Vuetify(opts);
