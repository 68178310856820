import CryptoJS from "crypto-js";
import config from "../config";

export default {
  encrypt(text, key) {
    return CryptoJS.AES.encrypt(text, key).toString();
  },

  decrypt(ciphertext, key) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    const output = bytes.toString(CryptoJS.enc.Utf8);
    return output;
  },

  redirectBase() {
    window.location.replace(config.defaultURL);
  },
};
