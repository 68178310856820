<template>
  <div></div>
</template>
<script>
export default {
  name: "NotFound",

  created() {
    window.location.replace("https://growcify.com");
  },
};
</script>
