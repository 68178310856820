import axios from "axios";

const baseAPIDev = "http://localhost:9000/v1";
const baseAPI = "https://api.growcify.com/v1";

import Util from "./services/util";

// create a new axios instance
const instance = axios.create({
  baseURL: process.env.NODE_ENV !== "production" ? baseAPIDev : baseAPI,
});

const agent = "application/web-app";
const nonce = "sKianQKk9r0ACRZ-9rdGURcvSW1E8mGZ0-6YZvcifo";
const control = "deflate;age=2;js;growcify/2022-0101;*/*-6YZvcifo";

instance.defaults.headers.common["X-Request-User-Agent"] = agent;
instance.defaults.headers.common["X-Request-User-Nonce"] = Util.encrypt(
  nonce,
  nonce
);
instance.defaults.headers.common["Accept-Control"] = Util.encrypt(
  control,
  control
);

export default instance;
