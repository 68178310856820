import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Apps from "../views/apps.vue";
import NotFound from "../views/not-found.vue";

const routes = [
  {
    path: "/a/:uuid",
    name: "apps",
    component: Apps,
  },
  {
    path: "/:slug/p/:uuid",
    name: "apps",
    component: Apps,
  },
  {
    path: "/:slug/r/:uuid",
    name: "apps",
    component: Apps,
  },
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

export default router;
