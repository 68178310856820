import Vue from "vue";
import VueAxios from "vue-axios";

import App from "./App.vue";

import http from "./http";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

//use default
Vue.use(VueAxios, http);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  vuetify,
}).$mount("#app");
