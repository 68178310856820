<template>
  <v-app>
    <Apps v-if="routeName == 'apps'" />
  </v-app>
</template>

<script>
import Apps from "./views/apps.vue";
import Util from "./services/util";

export default {
  name: "App",

  created() {
    const routeName = this.$route.name;
    if (!routeName) return Util.redirectBase();

    this.routeName = routeName;
  },

  components: {
    Apps,
  },

  data() {
    return {
      routeName: "",
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
